
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import { useModals } from '@/compositions/modals'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import { assigneeOptions } from '@/definitions/_general/_data/optionsList'
import { dueDate } from '@/definitions/shared/filters/filtersLib/data'
import TmButton from '@/components/shared/TmButton.vue'
import TmToggleButtons from '@/components/shared/TmToggleButtons.vue'
import { sortingOptions } from '@/definitions/deals/data'
import { useDeals } from '@/compositions/deals/useDeals'
import { useFilters } from '@/compositions/filters'
import { dealsFilter } from '@/definitions/shared/filters/filtersList/data'
import DropdownFilterCheckbox from '@/components/shared/filters/dropdown/DropdownFilterCheckbox.vue'
import DropdownFilterAmount from '@/components/shared/filters/dropdown/DropdownFilterAmount.vue'

export default defineComponent({
  components: {
    DropdownFilterAmount,
    DropdownFilterCheckbox,
    TmButton,
    TmToggleButtons,
    DropdownFilterRadio,
    DropdownFilterAssignee,
    TopFilter,
    TopFilterButton,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  setup() {
    const search = ref('')
    const { openModal } = useModals()
    const { openTransferOwnership, openDeleteDeals } = useDeals()
    const { filters } = useFilters(dealsFilter)

    return {
      filters,
      openTransferOwnership,
      openDeleteDeals,
      search,
      dueDate,
      assigneeOptions,
      openModal,
      sortingOptions,
    }
  },
})
