import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_toggle_buttons = _resolveComponent("tm-toggle-buttons")!
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_dropdown_filter_assignee = _resolveComponent("dropdown-filter-assignee")!
  const _component_dropdown_filter_checkbox = _resolveComponent("dropdown-filter-checkbox")!
  const _component_dropdown_filter_amount = _resolveComponent("dropdown-filter-amount")!
  const _component_dropdown_filter_radio = _resolveComponent("dropdown-filter-radio")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    search: _ctx.search,
    "onUpdate:search": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.search) = $event)),
    "search-placeholder": "Search deals",
    "show-filters": "",
    "show-buttons-text": "",
    "hide-columns-additional-buttons": "",
    selected: _ctx.selected
  }, {
    "filter-line-right-prepend": _withCtx(() => [
      _createVNode(_component_tm_toggle_buttons, { size: "large" }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_button, {
            "icon-only": "",
            class: _normalizeClass({'_active': _ctx.$route.name === 'base.deals.kanban' }),
            size: "large",
            "no-border": "",
            flat: "",
            to: { name: 'base.deals.kanban' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                name: "tmi-kanban",
                class: "neutral--text"
              })
            ]),
            _: 1
          }, 8, ["class", "to"]),
          _createVNode(_component_tm_button, {
            "icon-only": "",
            size: "large",
            class: _normalizeClass({'_active': _ctx.$route.name === 'base.deals.lists' }),
            "no-border": "",
            flat: "",
            to: { name: 'base.deals.lists' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                class: "neutral--text",
                name: "format_list_bulleted"
              })
            ]),
            _: 1
          }, 8, ["class", "to"])
        ]),
        _: 1
      })
    ]),
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "New deal",
        icon: "add",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('deals')))
      })
    ]),
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Transfer ownership",
        icon: "tmi-person-add",
        onClick: _ctx.openTransferOwnership
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button, {
        text: "Download",
        icon: "get_app"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDeleteDeals(_ctx.selected)))
      })
    ]),
    "filter-line-details": _withCtx(() => [
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.contact.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.contact.value) = $event)),
        options: _ctx.filters.contact.options,
        "search-placeholder": "Search users",
        label: "Contact"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_checkbox, _mergeProps({
        modelValue: _ctx.filters.organization.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters.organization.value) = $event))
      }, _ctx.filters.organization, { name: "Organization" }), null, 16, ["modelValue"]),
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.dealOwner.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters.dealOwner.value) = $event)),
        options: _ctx.filters.dealOwner.options,
        "search-placeholder": "Search users",
        label: "Deal owner"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_amount, {
        modelValue: _ctx.filters.amount,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters.amount) = $event)),
        label: "Amount"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        modelValue: _ctx.filters.dueDate.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filters.dueDate.value) = $event))
      }, _ctx.filters.dueDate, { name: "Closing date" }), null, 16, ["modelValue"])
    ]),
    _: 1
  }, 8, ["search", "selected"]))
}