
import { defineComponent } from 'vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useDeals } from '@/compositions/deals/useDeals'

export default defineComponent({
  components: {
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  setup() {
    const { openDeleteDeal, openTransferOwnership } = useDeals()

    return {
      openDeleteDeal,
      openTransferOwnership,
    }
  },
})
