
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmAssignee from '@/components/shared/TmAssignee.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import DealsActions from '@/components/pages/deals/DealsActions.vue'
import { formatDate } from '@/services/dateTimeService'
import { useDeals } from '@/compositions/deals/useDeals'
import DeadlineDate from '@/components/pages/shared/DeadlineDate.vue'
import type { KanbanColumnType } from '@/definitions/shared/kanban/types'
import { dealsColumnsMock } from '@/definitions/deals/data'

export default defineComponent({
  components: {
    DeadlineDate,
    TmEllipsis,
    TmStatus,
    DealsActions,
    TmAssignee,
    TmTable,
  },
  setup() {
    const { getFormattedValue } = useDeals()

    const getStatus = (column: string) => {
      return dealsColumnsMock.find((e: KanbanColumnType) => e.name === column)!.status
    }

    return {
      getStatus,
      getFormattedValue,
      formatDate,
    }
  },
})
