import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_deals_filter = _resolveComponent("deals-filter")!
  const _component_deals_table = _resolveComponent("deals-table")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    title: "Sales US",
    "no-scroll": ""
  }, {
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-top-right": _withCtx(() => [
      _renderSlot(_ctx.$slots, "page-content-top-right")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_deals_filter, {
        columns: _ctx.tableHeaders,
        "onUpdate:columns": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tableHeaders) = $event)),
        selected: _ctx.selected,
        class: "mb-4"
      }, null, 8, ["columns", "selected"]),
      _createVNode(_component_deals_table, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
        headers: _ctx.tableHeaders,
        items: _ctx.tableItems,
        "items-name": "deals",
        "show-selection": ""
      }, null, 8, ["modelValue", "headers", "items"])
    ]),
    _: 3
  }))
}