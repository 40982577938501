
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DealsFilter from '@/components/pages/deals/DealsFilter.vue'
import DealsTable from '@/components/pages/deals/DealsTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import type { DealsCardType } from '@/definitions/deals/types'

export default defineComponent({
  components: {
    DealsFilter,
    DealsTable,
    PageContent,
  },
  setup() {
    const selected = ref()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'name' },
      { text: 'Value', value: 'value' },
      { text: 'Contact name', value: 'contact-name' },
      { text: 'Organization', value: 'organization' },
      { text: 'Stage', value: 'stage' },
      { text: 'Next activity date', value: 'next-activity', hidden: true },
      { text: 'Closing date', value: 'closing-date' },
      { text: 'Deal owner', value: 'deal-owner' },
    ])

    const tableItems = ref<DealsCardType[]>(getTableData('deals'))

    return {
      tableItems,
      selected,
      tableHeaders,
    }
  },
})
