import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_tm_assignee = _resolveComponent("tm-assignee")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_deadline_date = _resolveComponent("deadline-date")!
  const _component_deals_actions = _resolveComponent("deals-actions")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-title": _withCtx((props) => [
      _createVNode(_component_tm_ellipsis, { class: "blue-on-hover pointer" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.title), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "body-cell-value": _withCtx((props) => [
      _createTextVNode(_toDisplayString(_ctx.getFormattedValue(props.row.value)), 1)
    ]),
    "body-cell-contact-name": _withCtx((props) => [
      _createVNode(_component_tm_assignee, {
        assignee: props.row.contactName,
        "small-avatar": "",
        overflow: ""
      }, null, 8, ["assignee"])
    ]),
    "body-cell-deal-owner": _withCtx((props) => [
      _createVNode(_component_tm_assignee, {
        assignee: props.row.dealOwner,
        "small-avatar": "",
        overflow: ""
      }, null, 8, ["assignee"])
    ]),
    "body-cell-stage": _withCtx((props) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(_ctx.getStatus(props.row.column))), null, 16)
    ]),
    "body-cell-closing-date": _withCtx((props) => [
      _createVNode(_component_deadline_date, {
        "formatted-date": "",
        "deadline-date": props.row.closingDate
      }, null, 8, ["deadline-date"])
    ]),
    "body-cell-next-activity": _withCtx((props) => [
      _createVNode(_component_deadline_date, {
        "formatted-date": "",
        "deadline-date": props.row.nextActivityDate
      }, null, 8, ["deadline-date"])
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_deals_actions)
    ]),
    _: 1
  }))
}