
import { defineComponent } from 'vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'

export default defineComponent({
  components: {
    DropdownFilterRadio,
  },
  setup() {
    const options = [
      { label: 'Any amount', radioValue: 'any' },
      { label: 'Custom amount', radioValue: 'custom', field: 'amount' },
    ]

    return {
      options,
    }
  },
})
