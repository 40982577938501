import { useModals } from '@/compositions/modals'
import { dealsTemplates, dealsStatuses } from '@/definitions/deals/data'

type UseDeals = () => {
  getFormattedValue: (value: number) => string;
  openTransferOwnership: () => void;
  openDeleteDeals: (selected: any[]) => void;
  openTemplateModal: () => void;
  openTemplatePreviewModal: () => void;
  openDeleteDeal: () => void;
  openDeletePipelineStage: (name: string) => void;
}

export const useDeals: UseDeals = () => {
  const { openModal } = useModals()

  const openTransferOwnership = () => {
    openModal('singleAssignee', {
      modalTitle: 'Transfer ownership',
      label: 'Deal owner',
      btnText: 'Transfer',
    })
  }

  const openDeleteDeal = () => {
    openModal('confirmation', {
      title: 'Delete deal',
      btnText: 'Delete',
      btnColor: 'error',
      text: 'Are you sure you would like to delete the selected deal? This action cannot be undone. ',
    })
  }

  const openDeleteDeals = (selected: any[]) => {
    openModal('confirmation', {
      title: 'Delete deals',
      btnText: `Delete ${selected.length} deal${selected.length > 1 ? 's' : ''}`,
      btnColor: 'error',
      text: [
        'Are you sure you would like to delete the selected ',
        {
          style: 'semi-bold',
          text: `${selected.length} deal${selected.length > 1 ? 's' : ''}`,
        },
        '? This action cannot be undone.',
      ],
    })
  }

  const getFormattedValue = (value: number) => {
    return `$${value.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')}`
  }

  const openTemplateModal = () => {
    openModal('selectTemplate', {
      templates: dealsTemplates,
      title: 'Select a new pipeline template',
    })
  }
  const openTemplatePreviewModal = () => {
    openModal('templatePreview', {
      title: 'Simple pipeline',
      backFunc: () => openTemplateModal(),
      nextFunc: () => openModal('dealsForm'),
      description: 'Manage leads and opportunities pipeline.',
      image: require('@/assets/images/deals/template-image.svg'),
      statuses: dealsStatuses,
      statusesLabel: 'Deals stages',
    })
  }

  const openDeletePipelineStage = (name: string) => openModal('dealsDelete', { stageName: name })

  return {
    openDeletePipelineStage,
    openTemplateModal,
    openTemplatePreviewModal,
    getFormattedValue,
    openDeleteDeals,
    openDeleteDeal,
    openTransferOwnership,
  }
}
